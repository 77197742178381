






















































































































































import { Component, Mixins } from "vue-property-decorator";
import TournamentRewardsComponent from "./TournamentRewards.component";

@Component
export default class TournamentRewards extends Mixins(TournamentRewardsComponent) { }
