import { Component, Vue } from "vue-property-decorator";
import {  GET_TOURNAMENT_REWARDS } from "../../../../api/report"
import moment from 'moment';
import Model from "@/models/Model";

@Component({
    components: {
    },
    filters: {
        date(val: any) {
            return val ? moment(val).local().format('DD-MM-YYYY HH:mm:ss') : ''
        },
        currency(value: any) {
            value = isNaN(value) ? 0 : value
            const val = (Math.floor(value * 100) / 100).toFixed(2).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
    }
})

class TournamentRewards extends Vue {
    private model: Model;

    public title = ''
    public prizePool = ''
    public startDate = ''
    public endDate = ''

    public rewardData: any

    public paginate = {
        page: 1,
        limit: 999
    }

    public pageSize = 0;
    public paginateShow = false;
    public dataLoading = false;

    constructor() {
        super();
        this.model = new Model();
    }

    private loaderStyle = {
        color: '#CFA137',
        loader: 'spinner',
        width: 145,
        height: 235,
        backgroundColor: '#000000',
        opacity: 0.5,
    }

    async mounted() {
        this.addRouteName()
        this.dataLoading = false
        await this.getTournamentRewards()
        this.dataLoading = true
    }

    private addRouteName(): void {
        this.model.Name = this.$route.name;
        this.$store
            .dispatch("addRouteName", this.model)
    }

    public async clickCallback(number: number): Promise<void> {
        number
        await this.getTournamentRewards()
    }

    private async getTournamentRewards(): Promise<void> {

        const loader = this.$loading.show(this.loaderStyle);
        const id = (this.$route.query.id || '').toString()
        const list = await GET_TOURNAMENT_REWARDS(id)
        this.rewardData = list.data;

        if (this.rewardData.metadata.length >= 1) {
            this.title = this.rewardData.pageInfo[0].title
            this.prizePool = this.rewardData.pageInfo[0].prizePool
            this.startDate = this.rewardData.pageInfo[0].startDate
            this.endDate = this.rewardData.pageInfo[0].endDate

            this.pageSize = this.rewardData.pageInfo[0].size;
        } else {
            this.title = ''
            this.prizePool = ''
            this.startDate = ''
            this.endDate = ''

            this.rewardData = []
        }

        loader.hide()
    }
}

export default TournamentRewards