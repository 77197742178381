var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", [
    _c("nav", { attrs: { "aria-label": "breadcrumb" } }, [
      _c("ol", { staticClass: "breadcrumb" }, [
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              { attrs: { id: "'breadcrumb0", to: { name: "WinLose" } } },
              [
                _c("span", { attrs: { to: "" } }, [
                  _vm._v(_vm._s(_vm.$t("REPORT.REPORT")))
                ])
              ]
            )
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "breadcrumb-item" },
          [
            _c(
              "router-link",
              {
                attrs: { id: "'breadcrumb0", to: { name: "TournamentWinLose" } }
              },
              [
                _c("span", { attrs: { to: "" } }, [
                  _vm._v(_vm._s(_vm.$t("HOME.TOURNAMENT_WL")))
                ])
              ]
            )
          ],
          1
        ),
        _c("li", { staticClass: "breadcrumb-item" }, [
          _c("span", [_vm._v(_vm._s(_vm.$t("HOME.TOURNAMENT_REWARDS")))])
        ])
      ])
    ]),
    _c("div", [
      _c("div", { staticClass: "section-group" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-2 text-right" }, [
            _c("label", { staticClass: "bold" }, [
              _vm._v(_vm._s(_vm.$t("REPORT.TIER_NAME")) + " : ")
            ])
          ]),
          _c("div", { staticClass: "col-2 text-left" }, [
            _vm._v(_vm._s(_vm.title))
          ]),
          _c("div", { staticClass: "col-2 text-right" }, [
            _c("label", { staticClass: "bold" }, [
              _vm._v(_vm._s(_vm.$t("REPORT.PRIZE_POOL")) + " : ")
            ])
          ]),
          _c("div", { staticClass: "col-4 text-left" }, [
            _vm._v(_vm._s(_vm._f("currency")(_vm.prizePool)))
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-2 text-right" }, [
            _c("label", { staticClass: "bold" }, [
              _vm._v(_vm._s(_vm.$t("FILTER.START_DATE")) + " : ")
            ])
          ]),
          _c("div", { staticClass: "col-2 text-left" }, [
            _vm._v(_vm._s(_vm._f("date")(_vm.startDate)))
          ]),
          _c("div", { staticClass: "col-2 text-right" }, [
            _c("label", { staticClass: "bold" }, [
              _vm._v(_vm._s(_vm.$t("FILTER.END_DATE")) + " : ")
            ])
          ]),
          _c("div", { staticClass: "col-4 text-left" }, [
            _vm._v(_vm._s(_vm._f("date")(_vm.endDate)))
          ])
        ]),
        _vm.dataLoading
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "row mt-2" }, [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("nav", { attrs: { "aria-label": "breadcrumb" } }, [
                      _c("ol", { staticClass: "breadcrumb" }, [
                        _c(
                          "li",
                          { staticClass: "breadcrumb-item" },
                          [
                            _c(
                              "router-link",
                              { attrs: { id: "breadcrumb1", to: {} } },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "linkNav bold",
                                    staticStyle: {
                                      "text-decoration": "underline"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("REPORT.REWARDS")) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-sm-6" },
                    [
                      _vm.paginateShow
                        ? _c("paginate", {
                            attrs: {
                              "page-count": _vm.pageSize,
                              "page-range": 3,
                              "prev-text": "<<",
                              "next-text": ">>",
                              "click-handler": _vm.clickCallback,
                              "container-class": "pagination float-right",
                              "prev-class": "inline",
                              "next-class": "inline",
                              "page-class": "inline"
                            },
                            model: {
                              value: _vm.paginate.page,
                              callback: function($$v) {
                                _vm.$set(_vm.paginate, "page", $$v)
                              },
                              expression: "paginate.page"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ])
            ])
          : _vm._e(),
        _c("div", { staticClass: "table-responsive" }, [
          _c("table", { staticClass: "table table-bordered table-hover" }, [
            _c("thead", [
              _c("tr", [
                _c(
                  "th",
                  {
                    staticClass: "col-text-padding",
                    staticStyle: { "text-align": "center" }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("REPORT.NO")) + " ")]
                ),
                _c(
                  "th",
                  {
                    staticClass: "col-text-padding",
                    staticStyle: { "text-align": "center" }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("REPORT.REWARDS")) + "(%) ")]
                ),
                _c("th", { staticStyle: { "text-align": "center" } }, [
                  _vm._v(" " + _vm._s(_vm.$t("REPORT.REWARD_PRICE")) + " ")
                ]),
                _c("th", { staticStyle: { "text-align": "center" } }, [
                  _vm._v(" " + _vm._s(_vm.$t("REPORT.LOGIN_NAME")) + " ")
                ])
              ])
            ]),
            !_vm.dataLoading
              ? _c("tbody", [_vm._m(0)])
              : _c(
                  "tbody",
                  [
                    _vm.rewardData.length == 0
                      ? [
                          _c("tr", [
                            _c("td", { attrs: { colspan: "4" } }, [
                              _vm._v(_vm._s(_vm.$t("DATA_NOT_FOUND")))
                            ])
                          ])
                        ]
                      : _vm._l(_vm.rewardData.metadata, function(r) {
                          return _c("tr", { key: r.rank }, [
                            _c("td", [_vm._v(_vm._s(r.rank))]),
                            _c("td", [_vm._v(" " + _vm._s(r.rewardPT) + " ")]),
                            _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("currency")(r.prizepool)) +
                                  " "
                              )
                            ]),
                            _c("td", [
                              _vm._v(" " + _vm._s(r.usernameWithPrefix) + " ")
                            ])
                          ])
                        })
                  ],
                  2
                )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c(
        "td",
        { staticStyle: { "text-align": "center" }, attrs: { colspan: "4" } },
        [
          _c("div", { staticClass: "d-flex justify-content-center" }, [
            _c(
              "div",
              {
                staticClass: "spinner-border text-primary",
                attrs: { role: "status" }
              },
              [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
            )
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }